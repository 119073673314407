( function ( _, $, ContentCarousel, TitleCarousel, ResponsiveImageBoxes ) {
    "use strict";

    /**
     * Sets up the JS functionality for a responsive gallery block, containing a Flickity carousel for the
     * gallery images (and possibly another carousel for titles and captions).
     *
     * Requires the $scope of the gallery to be set. That's the outermost container of this specific carousel,
     * which separates it from other (potential) carousels on the same page.
     *
     * The script tries to identify the actual carousel container by looking for a ".carousel-container"
     * descendant within the scope. Only if that descendant does not exist, the "scope" container is also
     * considered to be the actual carousel container.
     *
     * @param {jQuery}  $scope
     *
     * @param {Object}  [options]
     * @param {boolean} [options.showTitleCarousel=false]
     * @param {boolean} [options.disableFullscreen=false]
     * @param {boolean} [options.disableHash=false]
     */
    function createResponsiveGallery ( $scope, options ) {

        var $carouselContainer, contentCarousel,

            $mediaElement = $( ".media-element", $scope ),

            // Filter selects elements with a non-empty `data-get-max-width-from-anchor` attribute.
            // See https://stackoverflow.com/a/23944081/508355
            $gettingMaxWidthFromAnchor = $( $scope ).filter( "[data-get-max-width-from-anchor][data-get-max-width-from-anchor!='']" ),
            $maxWidthAnchor = $( ".max-width-anchor", $scope ),

            responsiveBoxes = new ResponsiveImageBoxes(
                $mediaElement,
                {
                    beforeUpdate: function () {
                        // We need to remove max-width constraints first to let the elements find their layout
                        // without interference. Once the carousel layout is final (as defined by the CSS and the
                        // responsive box JS mechanism), we apply new max-width constraints as required.
                        $gettingMaxWidthFromAnchor.css( { maxWidth: "none" } );
                    },

                    afterUpdate: function () {
                        $gettingMaxWidthFromAnchor
                            .filter( function () {
                                var $elem = $( this ),
                                    expectedBreakpoint = $elem.data( "get-max-width-from-anchor" );

                                if ( $elem.data( "ignore-max-width-anchor" ) ) {
                                    // Skipping, don't apply the anchor max-width
                                    return false;
                                } else if ( expectedBreakpoint.indexOf( " down" ) > -1 ) {
                                    // A breakpoint like "medium down" can't be queried directly, but can be
                                    // inferred. The condition is satisfied if the breakpoint is either "medium
                                    // only", or else "not medium" (and up).
                                    expectedBreakpoint = expectedBreakpoint.replace( " down", "" );
                                    return Foundation.MediaQuery.is( expectedBreakpoint + " only" ) || ! Foundation.MediaQuery.is( expectedBreakpoint );
                                } else {
                                    return expectedBreakpoint === "all" || Foundation.MediaQuery.is( expectedBreakpoint );
                                }
                            } )
                            .css( { maxWidth: $maxWidthAnchor.css( "max-width" ) } );
                    }
                } )
                .init();

        options = _.extend( {
            showTitleCarousel: false,
            disableFullscreen: false,
            disableHash: false
        }, options || {} );

        $carouselContainer = $( ".carousel-container", $scope );
        if ( !$carouselContainer.length ) $carouselContainer = $scope;

        contentCarousel = new ContentCarousel( $scope, {
            fullscreen: !options.disableFullscreen,
            hash: !options.disableHash,
            $container: $carouselContainer
        } )
            .enableLightnessClasses();

        if ( options.showTitleCarousel ) new TitleCarousel( { $container: $carouselContainer } );

        // Update the responsive boxes again, catching modifications which have been made while instantiating the
        // carousel(s).
        responsiveBoxes.update();

        // Make sure the responsive box adaptation mechanism runs after entering or exiting full-screen mode
        contentCarousel.$elem
            .on( "fullscreenChange.flickity", function ( event, isFullscreen ) {
                // Do not transfer the max-width constraint of the carousel (which preserves its aspect ratio) to
                // the entire gallery element when entering full-screen mode. The gallery element should be able to
                // expand, in order to center the carousel within it.
                $mediaElement.data( "ignore-max-width-anchor", isFullscreen );
                responsiveBoxes.update();
            } );

        // Setup is done, reveal the carousel
        $mediaElement.removeClass( "is-initializing" );
    }

    /**
     * Export global function
     */
    window.createResponsiveGallery = createResponsiveGallery;

} )( _, jQuery, ContentCarousel, TitleCarousel, ResponsiveImageBoxes );

